<template>
  <div>
    <p class="text-2xl mb-6">Historique des changements de paliers</p>
    <Filters></Filters>
    <v-card class="my-6 pa-6">
      <v-row>
        <v-col cols="3">
          <div class="tabs-container">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              :class="['tab-selector', { selected: selectedTab === index }]"
              @click="selectTab(index)"
            >
              {{ tab }}
            </div>
          </div>
        </v-col>
        <v-col cols="9">
          <div class="graph-container">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid
            animi asperiores, aut cumque deserunt dolorem ducimus eius eum
            excepturi exercitationem explicabo iste libero minima perspiciatis
            quis, quisquam ratione saepe ut.
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mdiCircle, mdiCircleOutline } from "@mdi/js";
import Filters from "@/components/web-perf/cwv/cwv-performance-breakdown/filters/Filters.vue";

export default {
  name: "ProjectsList",
  components: {
    Filters,
  },
  created() {},
  data() {
    return {
      tabs: ["AA", "BB", "CC", "DD", "EE"], // Array for dynamic generation
      selectedTab: 0, // Default selected tab
      icons: {
        mdiCircle,
        mdiCircleOutline,
      },
    };
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index; // Update the selected tab
    },
  },
  watch: {},
};
</script>
<style scoped>
.tabs-container {
  .tab-selector {
    border: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 0 0px #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: #f7f7f7;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
      transform: translateY(-3px);
    }

    &:active {
      background-color: #f5f5f5;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      transform: translateY(0);
    }

    &.selected {
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
      transform: translateY(-3px);
    }
  }
}
.graph-container {
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>
