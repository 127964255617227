<template>
  <v-card class="mb-6 pa-2">
    <v-row class="ma-3">
      <v-col cols="3">
        <site-filter store="webPerf"></site-filter>
      </v-col>
      <v-col cols="2">
        <device-filter store="webPerf"></device-filter>
      </v-col>
      <v-col cols="3">
        <page-type-filter store="webPerf"></page-type-filter>
      </v-col>
      <v-col cols="3">
        <date-filter store="webPerf"></date-filter>
      </v-col>
      <v-col cols="1">
        <apply-filters
          :disabled="disabled"
          outlined
          color="secondary"
          :icon="icons.applyIcon"
          title=""
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter.vue";
import DeviceFilter from "@/components/common/filters/DeviceFilter.vue";
import PageTypeFilter from "@/components/web-perf/cwv/filters/filters/PageTypeFilter.vue";
import { mdiPlayCircleOutline as applyIcon } from "@mdi/js";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    SiteFilter,
    DeviceFilter,
    PageTypeFilter,
  },
  data() {
    return {
      icons: {
        applyIcon,
      },
    };
  },
  computed: {
    disabled: {
      get() {
        return this.$store.getters["common/getApplyFilters"];
      },
      set(value) {
        this.$store.commit("common/updateApplyFilters", value);
      },
    },
  },
  watch: {},
};
</script>

<style scoped></style>
